<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">自缴缴费管理</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="editElectric">添加电费</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-select-remote-search label="电费项目" v-model="pageParam.params.projectId" :options="optionsElectric" placeholder="请选择电费项目(可搜索)" />
                <le-select-remote-search label="电表号" v-model="pageParam.params.ammeterId" :options="optionsAmmeter" placeholder="请选择电表(可搜索)" />
                <le-select-remote-search label="站点" v-model="pageParam.params.stationId" :options="optionsStation" placeholder="请选择站点(可搜索)" />
                <jd-input-condition 
                    label="电费占比" 
                    :value.sync="ratio" 
                    :condition.sync="pageParam.params.compareOp" 
                    :options='optionsCondition' 
                    showAppend
                    inputType="money">
                    <template>
                        <span>%</span>
                    </template>
                </jd-input-condition>
                <le-select-local-search label="项目类型" v-model="pageParam.params.projectType" :options="optionsProjectType" />
                <le-select-local-search label="缴费情况" v-model="pageParam.params.alertStatus" :options="optionsWarningInfo" />
            </le-search-form>
            <le-pagview ref="electricListPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.electricList" :pageSizeDefault='10'>
                <el-table ref="electricList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="province" label="电费项目ID" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.projectId || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="province" label="电费项目" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.name || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="province" label="电表数量" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.ammeterNum || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="province" label="站点数量" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationNum || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="province" label="设备总数/插槽总数" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.devNum || 0 }}/{{ row.slotNum || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="province" label="电费单价（/元/kwh）" min-width="150">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.unitPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="province" label="电费占比" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ util.numFormat(row.ratio) }}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="province" label="收益总额" min-width="140">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.totalProfit) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationName" label="已付总额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.totalPay) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="已付电量（kwh）" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.totalEnergy || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="当前电费结余" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.currentFee) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="项目类型" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.projectType | initDic(optionsProjectType) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="缴费情况" min-width="200">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs" v-if="row.alertInfo.length">
                                <span class="a-w-100" :class="alertInfoClass(item)" v-for="(item, index) in row.alertInfo" :key="index">{{ index + 1 }}：{{ item | initAlertInfo }}；</span>
                            </div>
                            <div v-else>正常</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="70" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="电费站点趋势" placement="top">
                                <img src="../../assets/icon/menu-chart-active.png" class="a-wh-16" @click="toObserveChart(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="更多" placement="top">
                                <el-dropdown placement="bottom-start" @command="handlerMoreOperate">
                                    <img src="../../assets/icon/more-operation.png" class="a-wh-16" />
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item 
                                            v-for="item in optionsMoreOperation" 
                                            :command='item.value+"#"+scope.$index' 
                                            :key="item.value" 
                                            :value="item.value">{{ item.label }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    let _this
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.electricBillPage,
                    method: "post",
                    params: {
                        ammeterId: '',
                        projectId: '',
                        stationId: '',
                        ratio: '',
                        compareOp: '',
                        projectType: '',
                        alertStatus: null
                    },
                    freshCtrl: 1,
                },
                ratio: '',
                optionsMoreOperation: [{
                    label: "站点维度详情",
                    value: 1,
                }, {
                    label: "账单维度详情",
                    value: 2,
                }],
                optionsStation: {
                    url: this.$Config.apiUrl.getStationList,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "stationId",
                    searchKey: "name",
                    pageSize: 100
                },//站点数据
                optionsElectric: {
                    url: this.$Config.apiUrl.electricFilter,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                    pageSize: 100
                },
                optionsAmmeter: {
                    url: this.$Config.apiUrl.ammeterFilter,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                    pageSize: 100
                },
                optionsCondition: [],
                optionsProjectType: [],
                optionsWarningInfo: [{
                    label: '全部',
                    value: null
                },{
                    label: '正常',
                    value: 0
                },{
                    label: '预警中',
                    value: 1
                }],
            }
        },
        created () {
            this.$getDic('chargeFeeType','select').then(res=>{ this.optionsProjectType = res; })
            this.$getDic('compareOp','select').then(res=>{ this.optionsCondition = res; })
            _this = this
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        filters: {
            initAlertInfo (value) {
                let alertRuleId = value.alertRuleId 
                let otherJson, txt
                try {
                    otherJson = JSON.parse(value.otherJson)
                } catch (error) {
                    console.log(error);
                }
                switch (alertRuleId) {
                    case 9:
                        // 物业缴费预警
                        let nowDate = _this.$getDay.getToday()
                        let dueDate = nowDate.split('-')[0] + '-' + otherJson.dueDate
                        let dueDateNum =  _this.$getDay.enumerateDaysBetweenDates(nowDate, dueDate).length - 1
                        txt = dueDateNum + '日后结账'
                        break;
                    case 10:
                        // 电费项目电费占比高预警
                        txt = '电费项目电费占比过高'
                        break;
                    case 11:
                        // 电表剩余费用预警
                        txt = '存在电表预估余额不足'
                        break;
                    default:
                        break;
                }
                return txt
            }
        },
        watch: {
            ratio (val) {
                if(val) {
                    this.pageParam.params.ratio = (val * 100).toFixed(0)
                }else {
                    this.pageParam.params.ratio = ''
                }
            }
        },
        methods:{
            //获取列表
            setTableData(data) {
                this.tableData = data
            },
            handlerRest() {
                this.pageParam.params = {
                    ammeterId: '',
                    projectId: '',
                    stationId: '',
                    ratio: '',
                    compareOp: '',
                    projectType: '',
                    alertStatus: null
                };
                this.ratio = ''
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['electricListPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            handlerMoreOperate (commands) {
                let command = commands.split('#')[0]
                let index = commands.split('#')[1]
                switch (command) {
                    case '1':
                        this.$router.push({
                            path: '/electric/electric-charge-station',
                            query:{
                                projectId: this.tableData[index].projectId,
                                chargeFeeType: this.tableData[index].projectType
                            }
                        })
                        break;
                    case '2':
                        this.$router.push({
                            path: '/electric/electric-charge-bill',
                            query:{
                                projectId: this.tableData[index].projectId,
                                chargeFeeType: this.tableData[index].projectType
                            }
                        })
                        break;
                    default:
                        break;
                }
            },
            toObserveChart (datas) {
                this.$router.push({
                    path: '/electric/electric-charge-chart',
                    query:{
                        id: datas?datas.projectId:'',
                    }
                })
            },
            // 添加电费账单
            editElectric () {
                this.$router.push({
                    path: '/electric/electric-charge-add',
                })
            },
            exportfile () {
                this.$exfile({
                    code: 17,
                    fileName: '电费项目总览导出',
                    startTime: this.$getDay.getSomeMonthDays().startTime,
                    endTime: this.$getDay.getSomeMonthDays().endTime,
                    params: {
                        ...this.pageParam.params,
                    }
                })
            },
            alertInfoClass (value) {
                let alertRuleId = value.alertRuleId 
                let otherJson, className
                try {
                    otherJson = JSON.parse(value.otherJson)
                } catch (error) {
                    console.log(error);
                }
                switch (alertRuleId) {
                    case 9:
                        // 物业缴费预警
                        let nowDate = _this.$getDay.getToday()
                        let dueDate = nowDate.split('-')[0] + '-' + otherJson.dueDate
                        let dueDateNum =  _this.$getDay.enumerateDaysBetweenDates(nowDate, dueDate).length - 1
                        className = dueDateNum + '日后结账'
                        if(dueDateNum<5){
                            className = 'alertInfoError'
                        }else if(dueDateNum >= 5 && dueDateNum < 10) {
                            className = 'alertInfoWarning'
                        }else {
                            className = 'alertInfoNormal'
                        }
                        break;
                    case 10:
                        // 电费项目电费占比高预警
                        className = 'alertInfoError'
                        break;
                    case 11:
                        // 电表剩余费用预警
                        className = 'alertInfoError'
                        break;
                    default:
                        break;
                }
                return className
            }
        }
    }
</script>

<style lang="scss" scoped>
.s-btn-add{
    width: 105px;
}
/deep/ .s-search-label{
    width: 100px
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
.alertInfoNormal{

}
.alertInfoWarning{
    color: #E6A23C;
}
.alertInfoError{
    color: #F56C6C;
}
</style>